<template>
    <div class="UserAnaly_B">
        <div class="Analy_B_box">
            <div class="analy_B_title">
                <span>确定目标用户</span>
            </div>
            <div class="rana_B_title">
                * 除选定的目标用户外还需要填写两类目标用户
            </div>
            <img class="_B_img_line" src="./../../../assets/img/line_1.png" alt="">
            <div class="_B_div_con">
                <ul class="_B_uls">
                    <li v-for="(item,index) in caseList" :key="index">
                        <div class="_B_div_one">
                            <div class="_one_img">
                                <span>主语</span>
                            </div>
                            <div class="_B_subject" v-if="index==0">
                                <span>{{item.role}}</span>
                            </div>
                            <div class="_B_subject _li_color" v-else>
                                <el-input v-model="item.role" placeholder="请输入主语" maxlength="12"></el-input>
                            </div>
                        </div>
                        <div class="_B_div_two">
                            <div class="_one_img">
                                <span>标签</span>
                            </div>
                            <ul class="_B_subjects">
                                <li v-for="(items,indexs) in item.tag.slice(0,3)" :key="indexs">
                                    <div class="_B_s_li" v-if="index==0">
                                        <img src="./../../../assets/img/label.png" alt="">
                                        <span>{{items}}</span>
                                    </div>
                                    <div class="_B_s_li _B_s_lis clearfix" v-else @mouseover="overp1(indexs)" @mouseout="outp1()">
                                        <img class="fl" src="./../../../assets/img/label.png" alt="">
                                        <span class="fl">{{items}}</span>
                                        <i class="el-icon-close fr" v-preventReClick @click="leClose(item,items)"></i>
                                    </div>
                                </li>
                                <el-input 
                                    v-model="item.inputValue" 
                                    :ref="'saveTagInput' + index" 
                                    v-if="item.inputVisible==true && index!=0" 
                                    @keyup.enter.native="handleInputConfirm(item)"
                                    @blur="handleInputConfirm(item)"
                                    size="mini"
                                    maxlength="12">
                                </el-input>
                                <div v-show="item.tag.length<=2 && index!=0 &&item.inputVisible==false" :class="item.tag.length==0 && item.inputVisible==false?'p_i_adds':'p_i_add'">
                                    <i class="el-icon-circle-plus-outline"  v-preventReClick @click="adds(item,index)"></i>
                                </div>
                            </ul>
                        </div>
                        <div class="_B_div_two _B_div_three">
                            <div class="_one_img">
                                <span>简要描述</span>
                            </div>
                            <div class="_B_describe" v-if="index == 0">
                                <span>{{item.desc2}}</span>
                            </div>
                            <div class="_B_describe" v-else>
                                <el-input type="textarea" maxlength="50" v-model="item.desc2" placeholder="请输入简要描述"></el-input>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <!-- 按钮 -->
            <div class="_B_butt_div">
                <div class="_B_button_one" v-preventReClick @click="prevstep()">
                    <span>上一步</span>
                </div>
                <div class="_B_button_two" v-preventReClick @click="nextBotn()">
                    <span>下一步</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            caseList:[],
            inputValue:'',
            inputVisible:false,
            p1Num:-1
        }
    },
    methods: {
        getapp(){
            this.axios.projectS1P2({
                token:this.$store.state.token,
                id:this.$store.state.cla_id
            }).then(res=>{
                if(res.data.code==0){
                    var str = res.data.data.qrcode
                    this.$emit('info', str);
                    var arr = []
                    res.data.data.values.forEach(item=>{
                        arr.push(
                            Object.assign({},item,{inputValue:'',inputVisible:false})
                        )
                    })
                    this.caseList = arr
                }else{
                    this.caseList = [] 
                }
            }).catch(err=>{

            })
        },
        // 添加一行
        adds(item,val){
            item.inputVisible = true;
            this.$nextTick(_ => {
                let name = 'saveTagInput' + val
                this.$refs[name][0].$refs.input.focus();
            });
        },
        // 删除一条
        leClose(item,items){
            item.tag.splice(item.tag.indexOf(items), 1);
        },
        //鼠标移入
        overp1(val){
            this.p1Num = val
        },
        outp1(){
            this.p1Num = -1
        },
        handleInputConfirm(val) {
            let inputValue = val.inputValue;
            console.log(val)
            if (inputValue) {
                val.tag.push(inputValue);
            }
            val.inputVisible = false;
            val.inputValue = '';
        },
        prevstep(){
            this.$router.push({name:'UserAnaly_A'})
        },
        nextBotn(){
            this.axios.Sets1({
                token:this.$store.state.token,
                id:this.$store.state.cla_id,
                node:'p2',
                value:this.caseList,
            }).then(res=>{
                if(res.data.code==0){
                    this.$router.push({name:'UserAnaly_C'})
                }else{
                    this.$message.error(res.data.message)
                }
            }).catch(err=>{

            })
        }
    },
    mounted() {
        this.getapp()
    }
}
</script>
<style scoped>
@import './../../../assets/css/student/A/UserAnaly_B.css';
</style>
<style>
._B_subject .el-input__inner{
    padding:0;
    background: transparent;
    border: none;
    color: #fff;
    text-align: center;
}
._B_describe .el-textarea{
    width: 100%;
    height: 100%;
}
._B_describe .el-textarea__inner{
    width: 100%;
    height: 100%;
    line-height: 1.5;
    padding:0;
    background: transparent;
    border: none;
    resize: none;
    color: #fff;
    text-align: justify;
    text-justify: distribute;
    word-break:break-all;
    display:-webkit-box;/**对象作为伸缩盒子模型展示**/
    -webkit-box-orient:vertical;/**设置或检索伸缩盒子对象的子元素的排列方式**/
    -webkit-line-clamp:4;/**显示的行数**/
    overflow:hidden;/**隐藏超出的内容**/
    font-family: 'Open Sans', Helvetica, Arial, sans-serif;
}
._B_subjects .el-input--mini .el-input__inner{
    height: 20px;
    line-height: 20px;
    font-size: 14px;
    font-family: 'Open Sans', Helvetica, Arial, sans-serif;
    color: #fff;
    padding:0;
    background: transparent;
    border: none;
    border-bottom: 1px solid #34FFFF;
    border-radius: 0;
    padding: 0 10px;
    box-sizing: border-box;
    margin-bottom: 5px;
}
</style>